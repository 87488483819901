import React from "react"
import {FormattedMessage, injectIntl, useIntl} from "react-intl"
import {Link} from "gatsby-plugin-react-intl"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import Parser from "html-react-parser"

import head_ru from "../images/index/head.png"
import head_en from "../images/index/head_en.png"
import {Card, CardGroup, Jumbotron} from "react-bootstrap"

const IndexPage = ({data}) => {
    const intl = useIntl()
    const lang = intl.locale
    let news = "";
    let head = "";
    if (lang === "ru") {
        news = data.ru_posts.edges;
        head = head_ru;
    } else if (lang === "en") {
        news = data.en_posts.edges;
        head = head_en;
    }
    console.log(head)

    return (
        <Layout>
            {/* --------------------------------- */}
            <Jumbotron>
                <img src={head} width="90%" className="center-block" alt="head"/>
                <hr/>
                <h3 style={{color: `rgb(18, 64, 171)`}}><FormattedMessage id="jumbotron.labintro"/></h3>
                <p id="lead"><FormattedMessage id="jumbotron.lead"/></p>
                {Parser(intl.formatMessage({id: "jumbotron.list"}))}
                <Link to="/about" className="btn btn-primary btn-lg">
                    <FormattedMessage id="jumbotron.about"/>
                </Link>
            </Jumbotron>
            {/* ------------------------------ */}

            <CardGroup>
                <Card>
                    <Card.Body>
                        <Card.Title><FormattedMessage id="more.nuclear_title"/></Card.Title>
                        <Card.Text>
                            <FormattedMessage id="more.nuclear_body"/>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Link to="/projects/physics" className="btn btn-info">
                            <FormattedMessage id="more.nuclear_more"/>
                        </Link>
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title><FormattedMessage id="more.education_title"/></Card.Title>
                        <Card.Text>
                            <FormattedMessage id="more.education_body"/>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Link to="/projects/education" className="btn btn-info">
                            <FormattedMessage id="more.education_more"/>
                        </Link>
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title><FormattedMessage id="more.software_title"/></Card.Title>
                        <Card.Text>
                            <FormattedMessage id="more.software_body"/>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Link to="/projects/software" className="btn btn-info">
                            <FormattedMessage id="more.software_more"/>
                        </Link>
                    </Card.Footer>
                </Card>
            </CardGroup>

            <hr style={{marginBottom: `50px`}}/>
            {/* --------------------------------- */}
            <h1 style={{textAlign: `center`}}><FormattedMessage id="more.news"/></h1>
            {news.map(({node}) => {
                const title = node.frontmatter.title;
                const date = node.frontmatter.date;
                const html = node.html;
                return (
                    <div className="card" key={node.id} id={node.id}
                         style={{marginBottom: `15px`, borderRadius: `0px`, boxShadow: `0 2px 2px #A2A2A2`}}>
                        <div className="card-body">
                            <h2 className="title">
                                {title}<span id="date">{date}</span>
                            </h2>
                            <div className="news">{Parser(html)}</div>
                        </div>
                    </div>
                )
            })}
        </Layout>
    )
}

export default injectIntl(IndexPage)

export const query = graphql`
    query {
        ru_posts: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "post"}, published: {ne: false}, language: {eq: "ru"}}},
            sort: {fields: [frontmatter___date], order: DESC}, limit: 3 )
        {
            edges{
                node{
                    id
                    html
                    frontmatter {
                        date(formatString: "DD.MM.YYYY")
                        title
                        language
                    }
                }
            }
        }

        en_posts: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "post"}, published: {ne: false}, language: {eq: "en"}}},
            sort: {fields: [frontmatter___date], order: DESC}, limit: 3 )
        {
            edges{
                node{
                    id
                    html
                    frontmatter {
                        date(formatString: "DD.MM.YYYY")
                        title
                        language
                    }
                }
            }
        }


    }`

